/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Tengo el placer de presentar a un nuevo amigo que ha entrado en la blogosfera. ", React.createElement(_components.a, {
    href: "http://electrolitoblog.wordpress.com/",
    title: "Electrolitoblog"
  }, "Electrolitoblog"), " es una bitácora sobre electrónica e informática que empieza pegando fuerte con un tema que ya hemos visto aquí, la ", React.createElement(_components.a, {
    href: "https://litox9.wordpress.com/2012/11/18/red-de-hogar/"
  }, "red de hogar"), ". Le deseo mucha suerte en su nueva andadura por la red y a ver que aprendemos de él.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
